body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.searchInput .Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
.searchSelect .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.43);
  border-width: 1px;
}

.searchInput .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.searchSelect .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.43);
}

div.searchSelect,
div.searchInput {
  width: 250px;
  margin-right: 8px;
}
@media (max-width: 700px) {
  div.searchInput {
    width: 250px;
  }
}

div.searchSelect.categories,
div.searchSelect.states {
  width: 250px;
  text-align: left;
}

button.searchButton {
  height: 56px;
  font-size: 1em;
}

.MuiFormControlLabel-root.category-select {
  align-items: flex-start;
  margin-left: 0;
  /* margin-bottom: 4px; */
}

.MuiFormControlLabel-root.category-select > span {
  padding: 0;
  padding-top: 3px;
}

.MuiFormControlLabel-root.category-select svg {
  height: 0.8em;
  width: 0.8em;
  padding-right: 4px;
}
.MuiFormControlLabel-root.category-select .MuiFormControlLabel-label {
  font-size: 0.9em;
}
